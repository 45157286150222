import { Button, Col, Form, Input, message } from 'antd';
import { FC, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../helper/helper';
import './_ZipcodeSection.scss';

const currentLocation: { [key: string]: number | null } = {
  lat: null,
  lng: null,
};

const options = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 0,
};

function success(pos: any) {
  const crd = pos.coords;
  currentLocation.lat = crd.latitude;
  currentLocation.lng = crd.longitude;
}

function error(err: any) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

navigator.geolocation.getCurrentPosition(success, error, options);

interface ZipcodeSectionProps {
  findNearbyBarbers: (lat: number, lng: number) => void;
  setFailedFind: () => void;
}

export const ZipcodeSection: FC<ZipcodeSectionProps> = ({
  findNearbyBarbers,
  setFailedFind,
}) => {
  const [address, setAddress] = useState<string | null>(null);

  const handleFindByZipcode = (values: any) => {
    const zipCode = values.zipCode;

    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${GOOGLE_MAPS_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        const { results } = data;
        if (results && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setAddress(results[0].formatted_address);
          findNearbyBarbers(Number(lat), Number(lng));
        } else {
          setAddress(null);
          setFailedFind();
        }
      })
      .catch((error) => {
        console.error('Error retrieving geolocation:', error);
        setAddress(null);
        setFailedFind();
      });
  };

  const handleFindNearby = () => {
    if (currentLocation.lat == null || currentLocation.lng == null) {
      message.error('Please turn on location service!');
      return;
    }
    const latlng = `${currentLocation.lat},${currentLocation.lng}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${GOOGLE_MAPS_API_KEY}`
    )
      .then((response) => response.json())
      .then(async (data) => {
        const { results } = data;
        if (results && results.length > 0) {
          setAddress(results[0].formatted_address);
          findNearbyBarbers(currentLocation.lat!, currentLocation.lng!);
        } else {
          setAddress(null);
          setFailedFind();
        }
      })
      .catch((error) => {
        console.error('Error retrieving geolocation:', error);
        setAddress(null);
        setFailedFind();
      });
  };

  return (
    <div className="zipcode-section-container">
      <Form className="zipcode-section-form" onFinish={handleFindByZipcode}>
        <Form.Item
          name="zipCode"
          label="Zip Code"
          rules={[{ required: true, message: 'Please enter a zip code' }]}
        >
          <Input />
        </Form.Item>

        <Col>
          <Button type="primary" htmlType="submit">
            Find barber shop by zip code
          </Button>
          <div style={{ margin: '10px' }}>or</div>
          <Button onClick={handleFindNearby}>Find barber shop near you</Button>
        </Col>
        <div className="zipcode-section-address">
          {address ? `Finding near: ${address}` : `No location found`}
        </div>
      </Form>
    </div>
  );
};

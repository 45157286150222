import { LinkOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import Link from 'antd/es/typography/Link';
import { FC, useEffect, useState } from 'react';
import { getGoogleMapsLinkByPlaceId, truncate } from '../../../helper/helper';
import { placesService } from '../../../helper/place_service';
import './_BarberInfoModal.scss';
interface BarberInfoModalProps {
  placeId: string;
  open: boolean;
  toggleOpen: (status: boolean) => void;
}

export const BarberInfoModal: FC<BarberInfoModalProps> = ({
  placeId,
  open,
  toggleOpen,
}) => {
  useEffect(() => {
    getPlaceDetails(placeId);
  }, [placeId]);

  const [barberInfo, setBarberInfo] = useState<google.maps.places.PlaceResult>(
    {}
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const getPlaceDetails = (placeId: string | undefined) => {
    setLoading(true);
    if (!placesService) {
      console.error('Places service not initialized');
      return;
    }
    if (placeId === undefined) {
      console.log('Input placeId empty or undefined');
      return;
    }
    let request = {
      placeId: placeId,
      // fields: ['name', 'rating', 'formatted_phone_number'],
    };
    placesService.getDetails(request, detailSearchCallback);

    function detailSearchCallback(
      result: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) {
      // Turn off loading
      // If status is different from OK, means something is wrong
      setLoading(false);
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`Status not OK finding details for ${placeId}: ${status}`);
        return;
      }
      // If nothing found, then something is wrong too
      if (result === null) {
        console.log(`Nothing found finding details for ${placeId}`);
        return;
      }
      //  If found something, then set it as is
      setBarberInfo(result);
    }
  };

  const openingStatus = () => {
    console.log(barberInfo);
    if (barberInfo.opening_hours) {
      return barberInfo.opening_hours.isOpen() ? (
        <span className="barber-info-opening">Opening now!</span>
      ) : (
        <span className="barber-info-closed">Currently closed</span>
      );
    }
  };

  return (
    <Modal
      className="barber-info-modal"
      title={
        isLoading ? (
          <div>Loading barber details...</div>
        ) : (
          <span className="barber-info-title">
            <div className="key">{barberInfo.name}</div>
            <div className="value">
              {barberInfo.rating ? barberInfo.rating : '?'}/5⭐
              {barberInfo.user_ratings_total &&
                `(${barberInfo.user_ratings_total})`}
            </div>
          </span>
        )
      }
      open={open}
      onCancel={() => toggleOpen(false)}
      footer={null}
    >
      {isLoading ? (
        <div className="barber-info-loading">
          <Spin />
        </div>
      ) : (
        <>
          {openingStatus()}
          <br />
          <b>Address</b>: {barberInfo.formatted_address}
          {barberInfo.formatted_phone_number && (
            <>
              <br />
              <b>Phone number</b>: {barberInfo.formatted_phone_number}
            </>
          )}
          {barberInfo.website && (
            <>
              <br />
              <b>Website</b>:{' '}
              <Link target="_blank" href={barberInfo.website}>
                {truncate(barberInfo.website, 40)}
              </Link>
            </>
          )}
          <br />
          <br />
          <Link target="_blank" href={getGoogleMapsLinkByPlaceId(placeId)}>
            Details on Google Maps <LinkOutlined />
          </Link>
        </>
      )}
    </Modal>
  );
};

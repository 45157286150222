import './App.scss';
import FindBarberSection from './component/FindBarberSection';
import Footer from './component/Footer';
import GenerateHairSection from './component/GenerateHairSection';
import Header from './component/Header';
import ReplaceHairSection from './component/ReplaceHairSection';

function App() {
  return (
    <div className="App">
      <Header />
      {/* hugging face hair generation */}
      <GenerateHairSection />
      {/* hair replacer */}
      <ReplaceHairSection />
      {/* find barber using zip code and nearby */}
      <FindBarberSection />
      <Footer />
    </div>
  );
}

export default App;

import { useJsApiLoader } from '@react-google-maps/api';
import { Card, Col, Row, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';
import { FC, useState } from 'react';
import { GOOGLE_MAPS_API_KEY, truncate } from '../../helper/helper';
import { placesService } from '../../helper/place_service';
import ZipcodeSection from '../ZipcodeSection';
import BarberInfoModal from './BarberInfoModal';
import './_FindBarberSection.scss';

const DEFAULT_BARBER_IMAGE_URL =
  'https://media.istockphoto.com/id/1202387135/vector/scissors-and-comb-icon-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=-x5Y2GLNKdrDdnqG6pMsF1uAIDtedM3qwlMqgN8ZBJk=';
const NUM_BARBER_SHOW = 10;

type GoogleMapsLibraries = (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[];

// libraries defined outside to make compiler happy
const libraries: GoogleMapsLibraries = ['places'];

export const FindBarberSection: FC = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<google.maps.Map>();
  const [nearbyBarbers, setNearbyBarbers] = useState<
    google.maps.places.PlaceResult[]
  >([]);
  const [isFindingBarbers, setFindingBarbers] = useState<boolean>(false);
  const [isNoneFound, setNoneFound] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedBarberStore, setSelectedBarberStore] = useState<number>(0);

  const setFailedFind = () => {
    setFindingBarbers(false);
    setNoneFound(true);
    setNearbyBarbers([]);
  };

  const findNearbyBarberShop = (lat: number, lng: number) => {
    setFindingBarbers(true);
    setNoneFound(false);
    setNearbyBarbers([]);
    // if places service is not init yet, then no point continuing
    if (!placesService) return;

    const request = {
      location: new google.maps.LatLng(lat, lng),
      radius: 10000,
      type: 'hair_care',
    };

    placesService.nearbySearch(request, nearbySearchCallback);

    function nearbySearchCallback(
      results: google.maps.places.PlaceResult[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`Status not OK finding nearby barbers: ${status}`);
        setFindingBarbers(false);
        setNoneFound(true);
        return;
      }
      if (!results) {
        console.log('Nothing found finding nearby barbers');
        return;
      }
      const barbers = results.slice(0, NUM_BARBER_SHOW);
      setNearbyBarbers(barbers);
      setFindingBarbers(false);
      setNoneFound(false);
    }
  };

  const barberDescBuilder = (
    barber: google.maps.places.PlaceResult
  ): string => {
    let builder = '';
    if (barber.vicinity) {
      builder += `${truncate(barber.vicinity, 30)}`;
    }
    if (barber.formatted_phone_number) {
      builder += `\n${barber.formatted_phone_number}`;
    }
    if (barber.rating) {
      builder += `\n${barber.rating} ⭐`;
    }
    return builder;
  };

  const handleBarberStoreClick = (barberStoreI: number) => {
    setSelectedBarberStore(barberStoreI);
    setModalVisible(true);
  };

  return isLoaded ? (
    <div className="find-barber-section">
      <h1 className="nearby-barber-title">Like what you see? Try it IRL!</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <hr className="title-hbar" />
      </div>
      <ZipcodeSection
        findNearbyBarbers={findNearbyBarberShop}
        setFailedFind={setFailedFind}
      />
      <div className="nearby-barber-list">
        {isFindingBarbers ? (
          <Spin />
        ) : (
          nearbyBarbers.length > 0 && (
            <Col>
              <h2>💈 Time to get that cut 💈</h2>
              <Row className="nearby-barber-row">
                {nearbyBarbers.map((barber, i) => {
                  let image_url =
                    barber.photos !== undefined && barber.photos.length !== 0
                      ? barber.photos[0].getUrl()
                      : DEFAULT_BARBER_IMAGE_URL;
                  return (
                    <Col span="8" key={`nearby-barber-${i}`}>
                      <Card
                        onClick={() => handleBarberStoreClick(i)}
                        className="nearby-barber-card"
                        cover={
                          <div
                            className="image-container"
                            style={{ backgroundImage: `url(${image_url})` }}
                          />
                        }
                        content={barberDescBuilder(barber)}
                      >
                        <Meta
                          title={barber.name}
                          description={barberDescBuilder(barber)}
                        />
                      </Card>
                    </Col>
                  );
                })}

                {modalVisible && (
                  <BarberInfoModal
                    placeId={nearbyBarbers[selectedBarberStore].place_id!}
                    open={modalVisible}
                    toggleOpen={setModalVisible}
                  />
                )}
              </Row>
            </Col>
          )
        )}
      </div>

      {isNoneFound && (
        <h2>
          😭 <br /> No barbers near you found
        </h2>
      )}
    </div>
  ) : (
    <></>
  );
};

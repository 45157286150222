import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Slider, Tooltip } from 'antd';
import { FC, useState } from 'react';
import hairstyles from '../../../misc/hairstyles.json';
import './_StyleCLIPForm.scss';

const { Option } = Select;

interface StyleCLIPFormProps {
  handleUpload: (
    fetchImage: (capturedImage: string) => Promise<Response>
  ) => void;
  setHairstyleDesc?: (hairstyle: string) => void;
}

const TARGET_DEFAULT_VALUE = 'bowlcut';
const MANIPULATION_DEFAULT_VALUE = 4.1;
const DISENTANGLEMENT_DEAFULT_VALUE = 0.15;

export const StyleCLIPForm: FC<StyleCLIPFormProps> = ({
  handleUpload,
  setHairstyleDesc,
}) => {
  const [suggestedHair, setSuggestedHair] = useState<string>('');
  const [prompt, setPrompt] = useState<string>(TARGET_DEFAULT_VALUE);

  const [form] = Form.useForm();

  const handleReplaceHairstyle = () => {
    handlePromptChange(suggestedHair);
  };

  const handlePromptChange = (value: string) => {
    setPrompt(value);
    form.setFieldValue('target', value);
  };

  return (
    <Form
      className="captured-form-container"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      form={form}
      onFinish={(values) => {
        const fetchImage = async (capturedImage: string) => {
          if (setHairstyleDesc) {
            setHairstyleDesc(values.target);
          }
          return fetch('https://changehairstyleclip-a5ky3x72rq-uc.a.run.app', {
            method: 'POST',
            body: JSON.stringify({
              image: capturedImage,
              // neutral: values.neutral,
              target: `a face with a ${values.target}`,
              manipulation_strength: values.manipulation_strength,
              disentanglement_threshold: values.disentanglement_threshold,
            }),
          });
        };

        handleUpload(fetchImage);
      }}
    >
      <h1>Design your new hairstyle</h1>

      <Form.Item
        label={
          <span>
            Hairstyle&nbsp;
            <Tooltip title="Provide a rough description of what you want your new hair look like">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        name="target"
        rules={[{ required: true, message: 'Please tell us what you want!' }]}
        initialValue={TARGET_DEFAULT_VALUE}
      >
        <Input
          type="text"
          value={prompt}
          onChange={(e) => handlePromptChange(e.target.value)}
        />
      </Form.Item>

      <Form.Item label="Suggestion">
        <Select
          style={{ width: '60%', margin: '5px' }}
          onChange={(hairstyle) => {
            setSuggestedHair(hairstyle);
          }}
        >
          {hairstyles.map((hairstyle) => (
            <Option key={hairstyle} value={hairstyle}>
              {hairstyle}
            </Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleReplaceHairstyle}>
          Replace
        </Button>
      </Form.Item>

      <Form.Item
        label={
          <span>
            Closeness&nbsp;
            <Tooltip title="The higher the closeness, the closer the generated image becomes to the target description. Negative values move the generated image further from the target description">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        name="manipulation_strength"
        initialValue={MANIPULATION_DEFAULT_VALUE}
      >
        <Slider min={-10} max={10} step={0.1} />
      </Form.Item>

      <Form.Item
        label={
          <span>
            Specificity&nbsp;
            <Tooltip title="The higher the specificity, the more specific the changes are to the target attribute. Lower values mean that broader changes are made to the input image">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        initialValue={DISENTANGLEMENT_DEAFULT_VALUE}
        name="disentanglement_threshold"
      >
        <Slider min={0.08} max={0.3} step={0.01} />
      </Form.Item>
      <Button icon={<UploadOutlined />} type="primary" htmlType="submit">
        Upload Image
      </Button>
    </Form>
  );
};

import {
  CameraOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
  UndoOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Divider, Row, Spin } from 'antd';
import { RcFile } from 'antd/es/upload';
import Upload from 'antd/es/upload/Upload';
import { FC, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { getRandom, isURL } from '../../helper/helper';
import StyleCLIPForm from './StyleCLIPForm';
import './_ReplaceHairSection.scss';
import tips from './tips.json';

export const ReplaceHairSection: FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>();
  const [procImage, setProcImage] = useState<string | null>();
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [hairstyleDesc, setHairstyleDesc] = useState<string | null>('');

  const handleImageUpload = (file: RcFile) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Process the image data as a string
      setCapturedImage(reader.result?.toString());
    };
    reader.readAsDataURL(file);
    return false; // Prevent automatic upload
  };

  const openWebcam = () => {
    setProcImage(null);
    setCapturedImage(null);
    setShowWebcam(true);
  };

  const closeWebcam = () => {
    setShowWebcam(false);
    setCapturedImage(null);
  };

  const changeConfig = () => {
    setProcImage(null);
  };

  const retake = () => {
    setCapturedImage(null);
    setProcImage(null);
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc!);
      setShowWebcam(false);
    }
  };

  const handleUpload = async (
    fetchImage: (capturedImage: string) => Promise<Response>
  ) => {
    if (capturedImage) {
      setProcessing(true);
      fetchImage(capturedImage)
        .then(async (res) => {
          const processed = await res.text();
          if (isURL(processed)) {
            setProcImage(processed);
          } else {
            setProcImage(
              'https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Trollface_non-free.png/220px-Trollface_non-free.png'
            );
          }
          setProcessing(false);
        })
        .catch((err) => {
          console.log(err.message);
          setProcessing(false);
        });
    } else {
      console.error('Please capture an image first!');
      setProcessing(false);
    }
  };

  const retakeButton = (
    <div className="retake-button-container">
      <Button
        onClick={retake}
        className="retake-button"
        icon={<UndoOutlined />}
      >
        Take it again
      </Button>
    </div>
  );

  return (
    <div className="replace-hair-section">
      <h1 className="header-title">Try it on for yourself!</h1>
      <p className="header-intro">
        Not sure if that hairstyle will work on you? Worry no more!
        <br />
        Use this hairstyle replacer and see how you would like to be seen, right
        now!
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <hr className="title-hbar" />
      </div>
      {!capturedImage && (
        <div className="upload-master-container">
          <Upload
            beforeUpload={handleImageUpload}
            className="custom-upload"
            accept={'image/*'}
          >
            <div className="upload-container">
              <UploadOutlined className="upload-icon" />
              <div className="upload-text">Click to Upload</div>
            </div>
          </Upload>
          <h3>or</h3>
          <div>
            {showWebcam ? (
              <div className="webcam-container">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="webcam-preview"
                />
                <div className="webcam-buttons">
                  <Button
                    type="primary"
                    icon={<CameraOutlined />}
                    onClick={captureImage}
                    className="capture-button"
                  />
                  <Button
                    onClick={closeWebcam}
                    className="close-button"
                    icon={<DeleteOutlined />}
                  />
                </div>
              </div>
            ) : (
              <div onClick={openWebcam} className="upload-container">
                <CameraOutlined className="upload-icon" />
                <div className="upload-text">Take a Picture</div>
              </div>
            )}
          </div>
        </div>
      )}

      {capturedImage && !isProcessing && !procImage && (
        <div className="captured-container">
          <img src={capturedImage} alt="Captured" className="captured-image" />
          {/* <HairCLIPForm handleUpload={handleUpload} /> */}
          <StyleCLIPForm
            handleUpload={handleUpload}
            setHairstyleDesc={setHairstyleDesc}
          />
          {retakeButton}
        </div>
      )}
      {isProcessing && (
        <div className="processing-container">
          <h2>Hang tight</h2>
          <Spin />
          <h2>Your new hairstyle is in the works...</h2>
        </div>
      )}
      {procImage && !isProcessing && (
        <div className="processed-container">
          <h2>New hair, new you 💅</h2>
          <h3>
            Hairstyle: <i>{hairstyleDesc}</i>
          </h3>
          <p style={{ fontStyle: 'italic', maxWidth: '550px' }}>
            Disclaimer: Sometimes the configuration won't be right or your input
            is invalid, so the resulting image might be a big black image. When
            that happens, simply use a different picture of you or change the
            hairstyle.
          </p>

          <img src={procImage} alt="Processed" className="processed-image" />
          <Row className="processed-image-buttons" align={'middle'}>
            <Button
              onClick={changeConfig}
              className="change-config-button"
              icon={<SettingOutlined />}
            >
              Run it back
            </Button>
            {retakeButton}
          </Row>
          <div className="processed-image-tips">
            <h2 className="processed-image-tips-title">
              <ExclamationCircleOutlined /> Tip
            </h2>
            <Divider
              style={{ backgroundColor: 'white', margin: '5px' }}
            ></Divider>
            <br />
            {getRandom(tips)}
          </div>
        </div>
      )}
    </div>
  );
};

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Slider,
  Spin,
  Tooltip,
} from 'antd';
import { FC, useState } from 'react';
import hairstyles from '../../misc/hairstyles.json';
import './_GenerateHairSection.scss';

const { Option } = Select;

export const GenerateHairSection: FC = () => {
  const [generatedImage, setGeneratedImage] = useState<string | null>();
  const [isGenerating, setGenerating] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');
  const [suggestedHair, setSuggestedHair] = useState<string>('');

  const [guidanceScale, setGuidanceScale] = useState<number>(8);
  const [numInferences, setNumInferences] = useState<number>(20);

  const [form] = Form.useForm();

  const handlePromptChange = (value: string) => {
    setPrompt(value);
    form.setFieldValue('prompt', value);
  };

  const handleGuidanceScaleChange = (value: number | null) => {
    if (value) {
      setGuidanceScale(value);
    }
  };

  const handleNumInferencesChange = (value: number | null) => {
    if (value) {
      setNumInferences(value);
    }
  };

  const handleUpload = (values: any) => {
    setGeneratedImage(null);
    setGenerating(true);
    if (values) {
      fetch('https://generatehair-a5ky3x72rq-uc.a.run.app', {
        method: 'POST',
        body: JSON.stringify(values),
      })
        .then(async (res) => {
          const processed = await res.json();
          setGeneratedImage(processed[0]);
          setGenerating(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const handleAddHairstyle = () => {
    const newPrompt = prompt + (prompt ? ' ' : '') + suggestedHair;
    form.setFieldValue('prompt', newPrompt);
    setPrompt(newPrompt);
  };

  return (
    <div className="generate-hair-container-container">
      <div className="generate-hair-container">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleUpload}
          form={form}
        >
          <Form.Item
            label="Prompt"
            name="prompt"
            rules={[{ required: true, message: 'Please enter a prompt' }]}
          >
            <Input
              value={prompt}
              onChange={(e) => handlePromptChange(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Hairstyle">
            <Select
              style={{ width: '80%', margin: '5px' }}
              onChange={(hairstyle) => {
                setSuggestedHair(hairstyle);
              }}
            >
              {hairstyles.map((hairstyle) => (
                <Option key={hairstyle} value={hairstyle}>
                  {hairstyle}
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={handleAddHairstyle}>
              Add
            </Button>
          </Form.Item>

          {/* guidance scale */}
          <Form.Item
            label={
              <span>
                Guidance Scale&nbsp;
                <Tooltip title="Choose between 5 and 20 to improve image's content">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            initialValue={guidanceScale}
            name="guidance_scale"
          >
            <Slider
              min={8}
              max={20}
              step={1}
              onChange={handleGuidanceScaleChange}
            />
          </Form.Item>

          {/* guidance scale indicator */}
          <InputNumber
            min={8}
            max={20}
            step={1}
            value={guidanceScale}
            onChange={handleGuidanceScaleChange}
          />

          {/* num of inferences */}
          <Form.Item
            label={
              <span>
                Number of Inferences&nbsp;
                <Tooltip title="Choose between 20 and 500 to improve image's resolution">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            initialValue={numInferences}
            name="num_of_inference"
          >
            <Slider
              min={20}
              max={500}
              step={1}
              value={numInferences}
              onChange={handleNumInferencesChange}
            />
          </Form.Item>

          {/* num of inference indicator */}
          <InputNumber
            min={20}
            max={500}
            step={1}
            value={typeof numInferences === 'number' ? numInferences : 0}
            onChange={handleNumInferencesChange}
          />

          {/* submit button */}
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="generate-submit-button"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        {generatedImage ? (
          <div className="image-filled">
            <img
              src={generatedImage}
              alt="Generated"
              className="image-filled-img"
            />
          </div>
        ) : (
          <div className="image-space">{isGenerating && <Spin />}</div>
        )}
      </div>
    </div>
  );
};

/**
 * Truncates the input string to size n. If go over, it will cut off the
 * tail part and add ... instead
 * 
 * @param str input str
 * @param n size of your desired string 
 * @returns formatted string like above
 */
export const truncate = (str: string, n: number) => {
  return (str.length > n) ? str.slice(0, n-1) + '...' : str;
};

/**
 * Takes a string and checks if the input string is an URL
 * 
 * @param str a string
 * @returns true if is URL (by regex gods), false otherwise
 */
export const isURL = (str: string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(str);
}

// yes, I let it open public like this, since it will be public on
// the network tab anyway
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDl7sWmJ7YuBi2GhzGJYd31mXUdeekHvrg';

export const getRandom = (items: Array<any>) => {
  return items[Math.floor(Math.random()*items.length)];
}

export const getGoogleMapsLinkByPlaceId = (placeId: string) => {
  return `https://www.google.com/maps/place/?q=place_id:${placeId}`;
}

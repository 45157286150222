import { FC } from 'react';
import './_Header.scss';

export const Header: FC = () => (
  <header className="App-header">
    <h2 className="header-title">Convenient Hairstyle 💇</h2>
    <p className="header-intro">
      Welcome to the forefront of AI-powered hairstyling! Discover limitless
      possibilities as our cutting-edge algorithms transform your vision into
      stunning hairstyles. Personalize your style, embrace the enchantment of
      technology, and redefine your look today!
    </p>
  </header>
);
